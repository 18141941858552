import * as React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import { AiFillDelete } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ICategory, IPost } from "./interfaces";

const CREATE_POST = gql`
  mutation($url: String!) {
    createPost(youtubeUrl: $url)
  }
`;
const DELETE_POST = gql`
  mutation($id: ID!) {
    deletePost(id: $id)
  }
`;

const POSTS = gql`
  {
    posts {
      thumbnail
      id
      channel {
        thumbnail
        title
        id
      }
      title
      publishedAt
      description
      content
    }
  }
`;

export const CATEGORIES = gql`
  query {
    categories {
      id
      title
      name
      isFeatured
    }
  }
`;
export const UPDATE_CATEGORY = gql`
  mutation($id: ID!, $data: UpdateCategoryDataInput!) {
    updateCategory(id: $id, data: $data) {
      id
      name
      isFeatured
    }
  }
`;

// TODO: just do styled components

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AppProps {}

const App: React.FC<AppProps> = () => {
  const [createPost] = useMutation(CREATE_POST);
  const [deletePost] = useMutation(DELETE_POST);
  const [updateCategory] = useMutation(UPDATE_CATEGORY);
  const { data, refetch: refetchPosts } = useQuery<{ posts: IPost[] }>(POSTS);
  const { data: categoriesData } = useQuery<{ categories: ICategory[] }>(
    CATEGORIES
  );

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: {
      url: "",
    },
    onSubmit: async (formValues, { resetForm }) => {
      createPost({
        variables: formValues,
      }).then(async () => {
        toast(`${formValues.url} has been added!`);
        refetchPosts();
      });
      resetForm();
    },
  });

  // if (loading) return <div>Loading...</div>;

  return (
    <div>
      <ToastContainer />
      <form
        onSubmit={handleSubmit}
        className="w-full py-4 flex flex-col items-center"
      >
        <input
          name="url"
          value={values.url}
          onChange={handleChange}
          className="border-b border-black w-8/12"
        />
        <button
          type="submit"
          className="px-4 py-2 border mt-2 transition-all duration-300 hover:bg-gray-400 hover:text-white rounded-lg"
        >
          Add Video
        </button>
      </form>
      <div className="grid grid-cols-3 w-full gap-4">
        {data?.posts.map((post) => {
          return (
            <div className="flex items-center" key={post.id}>
              <img className="max-h-20" src={post.thumbnail} alt={post.title} />
              <div className="px-4">
                <div className="line-clamp-2">{post.title}</div>
                <div className="flex flex-row">
                  <div
                    className="avatar--small h-8 w-8 block bg-cover self-center rounded-full"
                    style={{
                      backgroundImage: `url(${post.channel.thumbnail})`,
                    }}
                  />
                  <div className="flex flex-col p-2 leading-snug">
                    <div className="text-sm font-semibold">
                      {post.channel.title}
                    </div>
                  </div>
                </div>
              </div>
              <AiFillDelete
                size={50}
                className="ml-auto pl-4 flex-shrink-0 cursor-pointer"
                onClick={async () => {
                  await deletePost({
                    variables: {
                      id: post.id,
                    },
                  });
                  await refetchPosts();
                }}
              />
            </div>
          );
        })}
      </div>
      <div>
        <div>Categories:</div>
        <div>
          {categoriesData?.categories.map((category) => {
            return (
              <div key={category.id} className="flex">
                <div>{category.title ?? category.name}</div>
                <input
                  type="checkbox"
                  className="mx-2"
                  checked={category.isFeatured}
                  onChange={async (e) => {
                    await updateCategory({
                      variables: {
                        id: category.id,
                        data: {
                          isFeatured: e.target.checked,
                        },
                      },
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default App;
